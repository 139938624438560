import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import { Card, CardBody, Table } from 'reactstrap'
import BreadCrumb from 'src/Components/Common/BreadCrumb'
import UiContent from 'src/Components/Common/UiContent'
import MetaBar from 'src/Components/Common/MetaBar'
import ErrorPage, { DangerAlert } from '../ErrorPage'
import { useGetPromocodesGroupsQuery } from 'src/services/promocodesGroups'

const headers = {
  id: {},
  name: {}
}

const actionsEnabled = true

export const PromocodesGroups = () => {
  const { t } = useTranslation()

  const { data: groups, error } = useGetPromocodesGroupsQuery()

  const title = 'Группы промокодов'
  document.title = title
  const metaBar = <MetaBar createLink={'/promocodes-groups-create'} />

  if (error) {
    return <ErrorPage title={title} error={error} />
  }

  return (
    <>
      <UiContent />
      <div className='page-content'>
        <BreadCrumb title={title} />
        <Card>
          <CardBody>
            {metaBar}
            {error ? <DangerAlert error={error} /> : null}
            <div className='table-responsive table-card mt-4'>
              <Table hover className='table-sm align-middle table-nowrap mb-0'>
                <thead>
                  <tr>
                    <th />
                    {Object.keys(headers).map((header) => (
                      <th className='ps-3' key={header} scope='col'>
                        {t(headers[header].label || header)}
                      </th>
                    ))}
                    {actionsEnabled && <th scope='col'>{t('Actions')}</th>}
                  </tr>
                </thead>
                <tbody>
                  {groups &&
                    groups.length > 0 &&
                    groups?.map((entity) => {
                      return (
                        <tr key={entity.id}>
                          <td style={{ width: '1%' }}>
                            <Link
                              to={`/promocodes-groups-view?id=${entity.id}`}
                              className='fw-medium'
                            >
                              <i className='ri-eye-line fs-15' />
                            </Link>
                          </td>
                          {Object.keys(headers).map((fieldName) => {
                            if (fieldName === 'id')
                              return (
                                <td className='ps-3' key={fieldName}>
                                  {entity.id}
                                </td>
                              )
                            if (fieldName === 'name')
                              return (
                                <td className='ps-3' key={fieldName}>
                                  {entity.group_name}
                                </td>
                              )
                          })}
                          {actionsEnabled && (
                            <td>
                              <div className='hstack gap-3 flex-wrap'>
                                <Link
                                  to={`/promocodes-groups-edit?id=${entity.id}`}
                                  className='link-success fs-15'
                                >
                                  <i className='ri-edit-2-line'></i>
                                </Link>
                              </div>
                            </td>
                          )}
                        </tr>
                      )
                    })}
                </tbody>
              </Table>
            </div>
          </CardBody>
        </Card>
      </div>
    </>
  )
}
