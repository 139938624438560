import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import BreadCrumb from 'src/Components/Common/BreadCrumb'
import UiContent from 'src/Components/Common/UiContent'
import MetaBar from 'src/Components/Common/MetaBar'
import LoadingPage from '../LoadingPage'
import ErrorPage from '../ErrorPage'

import model from 'src/model'

import { Row, Col, Card, CardBody, Label, Input } from 'reactstrap'
import { useSearchParams } from 'react-router-dom'
import { useGetPromocodesGroupsQuery } from 'src/services/promocodesGroups'

const fieldsDescription = {
  id: {},
  group_name: { label: 'name' }
}

export const PromocodesGroupsView = () => {
  const { t } = useTranslation()

  const [searchParams] = useSearchParams()

  const [pageReady, setPageReady] = useState(false)
  const [paramError, setParamError] = useState(null)

  let id = searchParams.get('id')

  const { data } = useGetPromocodesGroupsQuery()

  const group = data.find((item) => item.id === Number(id))

  useEffect(() => {
    if (!id) setParamError('No entity id found')
  }, [id, searchParams])

  useEffect(() => {
    setPageReady(!!group)
  }, [group])

  const title = `${t('Holidays')}: ${t('View')}`
  document.title = title
  const bread = <BreadCrumb title={title} />
  const metaBar = (
    <MetaBar
      backLink={'/promocodes-groups'}
      editLink={group ? `/promocodes-groups-edit?id=${group.id}` : null}
      entity={group}
    />
  )

  if (paramError) {
    return (
      <ErrorPage
        backLink={'/promocodes-groups'}
        title={title}
        error={paramError}
      />
    )
  }

  if (!pageReady) {
    return <LoadingPage backLink={'/promocodes-groups'} title={title} />
  }

  return (
    <>
      <UiContent />
      <div className='page-content'>
        {bread}
        <Card>
          <CardBody>
            {metaBar}
            <Row className='mt-3'>
              {Object.keys(fieldsDescription).map((fieldName) => {
                return (
                  <Col
                    className={
                      fieldsDescription[fieldName].fullWidth
                        ? 'col-12 mt-3'
                        : 'mt-3 col-auto'
                    }
                    key={`${fieldName}`}
                  >
                    <Label htmlFor={fieldName}>
                      {t(fieldsDescription[fieldName].label || fieldName)}
                    </Label>
                    <Input
                      type='text'
                      className='form-control form-control-sm'
                      value={model.fieldValueToText(
                        fieldName,
                        group,
                        fieldsDescription
                      )}
                      disabled
                    />
                  </Col>
                )
              })}
            </Row>
          </CardBody>
        </Card>
      </div>
    </>
  )
}
