import { api, omitBlankEntries } from './api'

type Call = any

export type CallPerson = {
  id: number
  is_deleted: boolean
  name: string
  type: string
  type_id: number
}

type Group = {
  id: number
  name: string
  created_at: string
}

export type CallReason = {
  id: string
  name: string
  type: string
  type_id: number
}

export type CallResult = {
  id: string
  name: string
}

type ExtraCondition = {
  name: 'string'
  label: 'string'
  validOperators: ['string']
  valueType: string
}

export type CallSetting = {
  id: number
  client_type: string
  result_type: string
  client_type_id: number
  result_type_id: number
  need_reason: boolean
  need_promise_amount: boolean
  need_promise_date: boolean
  need_comment: boolean
  is_default: boolean
}

export const {
  useGetCallsQuery,
  useGetCallPersonsQuery,
  useGetGroupsQuery,
  useUpdateGroupMutation,
  useDeleteSegmentMutation,
  useGetCallReasonsByProfileIdQuery,
  useAddCallEntityMutation,
  useUpdateCallEntityMutation,
  useDeleteCallEntityMutation,
  useGetCallSettingsQuery,
  useAddCallSettingsMutation,
  useUpdateCallSettingsMutation,
  useDeleteCallSettingsMutation,
  useGetCallResultsByProfileIdQuery,
  useAddCallResultMutation,
  useGetSegmentExtraConditionsQuery,
  useGetSegmentCsvMutation,
  useArchiveEntityMutation
} = api.injectEndpoints({
  endpoints: (builder) => ({
    getCalls: builder.query<Call[], string>({
      query: (id) => `crm/v1/calling/${id}`,
      providesTags: (_result, _err, id) => [{ type: 'Calls', id }]
    }),
    getCallPersons: builder.query<CallPerson[], void>({
      query: () => `crm/v1/calling/person`,
      providesTags: ['CallPerson']
    }),
    getGroups: builder.query<Group[], string>({
      query: () => `crm/v1/calling/groups`,
      providesTags: (result = []) => [
        ...result.map(({ id }) => ({ type: 'Groups', id }) as const),
        { type: 'Groups' as const, id: 'LIST' }
      ]
    }),
    getCallReasonsByProfileId: builder.query<
      CallReason[],
      Partial<{ person_id: number }> | void
    >({
      query: (params) => ({
        url: `crm/v1/calling/reason`,
        params: params || undefined
      }),
      providesTags: ['CallReason']
    }),
    getCallResultsByProfileId: builder.query<CallResult[], any>({
      query: (params) => ({
        url: `crm/v1/calling/result`,
        params: params || undefined
      }),
      providesTags: ['CallResult']
    }),
    archiveEntity: builder.mutation<void, string>({
      query: (id) => ({
        url: `crm/v1/calling/queue-params/${id}`,
        method: 'POST'
      })
    }),
    addCallResult: builder.mutation<void, Partial<any>>({
      query: (body) => ({
        url: `crm/v1/calling/call-result`,
        method: 'POST',
        body: omitBlankEntries(body)
      }),
      invalidatesTags: ['Calls']
    }),
    updateGroup: builder.mutation<
      void,
      Pick<Group, 'id'> & { user_ids: string[]; new_name: string }
    >({
      query: ({ id, ...body }) => ({
        url: `crm/v1/calling/groups/${id}`,
        method: 'PUT',
        body: omitBlankEntries(body)
      }),
      invalidatesTags: (_result, _error, request) => [
        { type: 'Groups', id: request.id }
      ]
    }),
    deleteSegment: builder.mutation<any, string | null>({
      query: (id) => ({
        url: `crm/v1/calling/stages/${id}`,
        method: 'DELETE'
      })
    }),
    getSegmentExtraConditions: builder.query<ExtraCondition[], void>({
      query: () => ({
        url: `crm/v1/calling/stages/extra-conditions`
      })
    }),
    addCallEntity: builder.mutation<void, Partial<any>>({
      query: (body) => ({
        url: `crm/v1/calling/entity`,
        method: 'POST',
        body: omitBlankEntries(body)
      }),
      invalidatesTags: ['CallPerson', 'CallReason']
    }),
    updateCallEntity: builder.mutation<void, Partial<any>>({
      query: (body) => ({
        url: `crm/v1/calling/entity`,
        method: 'PUT',
        body: omitBlankEntries(body)
      }),
      invalidatesTags: ['CallPerson', 'CallReason']
    }),
    deleteCallEntity: builder.mutation<any, Partial<any>>({
      query: (body) => ({
        url: `crm/v1/calling/entity`,
        method: 'DELETE',
        body: omitBlankEntries(body)
      }),
      invalidatesTags: ['CallPerson', 'CallReason', 'CallSettings']
    }),
    getCallSettings: builder.query<
      CallSetting[],
      Partial<{ person_id: number }> | void
    >({
      query: (params) => ({
        url: `crm/v1/calling/settings`,
        params: params || undefined
      }),
      providesTags: ['CallSettings']
    }),
    addCallSettings: builder.mutation<void, Partial<any>>({
      query: (body) => ({
        url: `crm/v1/calling/settings`,
        method: 'POST',
        body: omitBlankEntries(body)
      }),
      invalidatesTags: ['CallSettings']
    }),
    updateCallSettings: builder.mutation<void, Partial<any>>({
      query: (body) => {
        const { id, ...rest } = body
        return {
          url: `crm/v1/calling/settings?settings_id=${id}`,
          method: 'PUT',
          body: omitBlankEntries(rest)
        }
      },
      invalidatesTags: ['CallSettings']
    }),
    deleteCallSettings: builder.mutation<any, Partial<any>>({
      query: (body) => ({
        url: `crm/v1/calling/settings`,
        method: 'DELETE',
        body: omitBlankEntries(body)
      }),
      invalidatesTags: ['CallSettings']
    }),
    getSegmentCsv: builder.mutation<Blob, number>({
      query: (stage_id) => ({
        url: `crm/v1/calling/stages/${stage_id}/export-csv`,
        method: 'GET',
        responseHandler: (response) => response.blob()
      })
    })
  })
})
